<template>
  <div id="app">
    <Header />
    <router-view class="main" />
    <Footer />
  </div>
</template>
<script>
import Header from './components/header.vue'
import Footer from './components/footer'
export default {
  components: {
    Header,
    Footer
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  .main {
    flex: 1;
  }
}

</style>
