<template>
  <div class="header-box">
    <div class="header">
      <div class="logo">
        北京金小央教育科技有限公司
        <!-- <img src="https://img.fshtb.cn/assets/image/dqiu-logo@2x.d280f2a037b2d33cb2f0c45f015d1c4b.png" alt=""> -->
      </div>
      <div class="nav-list">
        <div @click="jump(index, item.path)" v-for="(item, index) in navData" :key="index" :class="[currentIndex == index ? 'active' : '', 'nav-item']">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      navData: [
        { name: '关于我们', path: '/' },
        { name: '最新动态', path: '/news' }
      ],
      currentIndex: 0
    }
  },
  methods: {
    jump (index, path) {
      this.currentIndex = index
      this.$router.push({ path: path })
    }
  }
}
</script>
<style lang="scss" scoped>
.header-box {
  width: 100%;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  box-shadow: 0 2px 8px 0 #cccccc;
  .header {
    width: 1200px;
    display: flex;
    align-items: center;
    .logo {
      height: 100%;
      padding: 10px 0;
      display: flex;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      img {
        height: 100%;
      }
    }
    .nav-list {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      line-height: 60px;
      .nav-item {
        margin-left: 30px;
        cursor: pointer;
        &.active {
          color: #3eb2bf;
        }
      }
    }
  }
}
</style>
