<template>
  <div class="home-container">
    <div class="home">
      <p>金小央考研交流APP1.0.3（以下简称“金小央APP”）成立于2020年，该APP致力于打造一款“一站式、极简化、专业型”APP。
        通过金小央APP，考研学生可以一站式获取考研倒计时、考研最新信息、相关专业考试题库、答疑交流服务、每日打卡、创始团队提供的高质量资料等服务；
        已录取学生可以获取实习工作指导课程、实习工作交流、租房交流、校园生活与学习分享等功能。
        通过金小央APP，考生可以迅速找到报考院校的“专业组织”，一个软件解决考研大部分问题！</p>
        <h3>一、金小央APP简介</h3>
        <p>
          <img src="../assets/pic_1.png" alt="">
        </p>
        <h5>1.考研倒计时</h5>
        <p>考生每次登陆“金小央APP”时，在主界面均可看到距离考研还有“XXX”天，无需下载专用的倒计时软件即可得到倒计时提醒。</p>
        <h5>2.资讯</h5>
        <p>资讯栏分为官方信息和原创信息两个子栏目。其中，官方信息主要提供来自报考院校官方网站的信息，原创信息则主要提供与考研相关但非报考院校发布的信息。</p>
        <p>官方网站信息主要来源于复旦研招网（报考院校研招网）、复旦大学经济学院、复旦大学大数据学院、复旦大学数学学院等相关报考学院。信息内容主要是考试大纲、招生计划、考试人数、校线、考点安排、拟录取名单等考研相关信息。</p>
        <p>原创信息包含每日热点/知识点更新、相关考研信息整理两个专栏。</p>
        <h5>3.课程</h5>
        <div>公开课：</div>
        <p>（1）初、复试经验视频</p>
        <p>（2）实习、工作经验视频</p>
        <p>（3）知识点</p>
        <p>（4）可公开的课程视频</p>
        <p>（5）等</p>
        <div>私密课：</div>
        <p>（1）复旦 硕士四本教材讲解</p>
        <p>（2）知识点强化练习</p>
        <p>（3）模拟+真题+热点讲解</p>
        <p>（4）考前强化预测讲解</p>
        <p>（5）等</p>
        <h5>4.题库</h5>
        <p>为了帮助学生检测复习效果，金小央APP提供了“每日练习”、“章节/知识点练习”、“真题回顾”、“模拟练习”四个专项训练。</p>
        <p>考研学子只需手拿手机即可轻松达到练习、检测的目的，每一个题目做完以后即可“秒”查看答案、“秒”知错、“秒”提升。考生在“真题”、“模拟练习”两个模块还可以选择练习模式和考试模式两种不同的方式考查自己对知识的理解。</p>
        <h5>5.交流</h5>
        <p>交流栏分为答疑解惑和交流园地两个子栏。其中，答疑解惑是学习中的问题的交流，用户之间可以相互解答，交流园地则是非学习类的问题。</p>
        <p>（1）答疑解惑：</p>
        <p>该栏目提供复旦硕士官方参考教材疑问、习题指南疑问、其他相关专业问题。</p>
        <p>（2）交流园地：</p>
        <p>该栏目为考生提供初复试经验贴、实习工作、租房信息、校园学习与生活、其他等交流内容。</p>
        <p>初复试经验贴：方便考后考上的学生记录自己的经验。</p>
        <p>实习工作：实习、工作交流以及发布实习、工作等相关信息。</p>
        <p>租房：实时公布校园周边最新租房信息。</p>
        <p>校园学习与生活：入学后的学习与生活交流。</p>
        <p>其他：其他未分类为上述内容的信息。</p>
        <p>（3）打卡：</p>
        <p>考研打卡是一个监督和自我监督的小项目，我们在交流园地这里设置打卡功能方便考生打卡进行自我监督。金小央APP为考生打卡提供了“分享”功能，即如果你想得到外部监督，即可开启分享每日得到外部监督从而更加有效地激励自己。</p>
        <h3>二、金小央APP特点</h3>
        <p>金小央APP的特点：一站式、极简化、专业型。</p>
        <h5>1.一站式</h5>
        <p>金小央APP提供了考生初试（复试、实习就业、租房等功能正在完善中）备考阶段所需的几乎所有服务。以帮助考生通过金小央APP一站式获取所需服务，如需获取官方信息，只需登录金小央APP即可获取，无需考生自己动手点击各大官方网站，这就极大地提高了考生复习备考的效率。</p>
        <div>（1）结合考研专业课特点，创建专业型交流平台</div>
        <p>众所周知，每个高校的专业课都有自己的特点，然而，目前市面上提供的交流平台都是“大一统”式的“适应”所有高校的公共服务，这种公共服务的缺点是不能针对每个高校的专业课提供专业服务。金小央APP打破了传统交流平台的这一缺陷，致力于打造专门为特定高校、特定专业提供服务的专业型交流平台。</p>
        <div>（2）第一时间提供考研学子所需的信息服务</div>
        <p>金小央APP开创了“通知”专栏，该专栏下又分为“官方通知”和“最新消息”两栏，这两个皆是考研学子最为关心的信息，有了金小央APP，考研学子从此不用再像以前一样，为了获取报名信息去中国研究生信息网，为了获取自己学校的最新官方消息去报考学校的研招网，学生只需下载金小央APP在“通知”专栏即可获取这些必要信息，一APP搞定所有信息。</p>
        <div>（3）提供每日练习、章节练习、真题、模拟练习等题库</div>
        <p>为了帮助学生检测复习效果，金小央APP提供了“每日练习”、“章节/知识点练习”、“真题回顾”、“模拟练习”四个专项训练，考研学子只需手拿手机即可轻松达到练习、检测的目的，每一个题目做完以后即可“秒”查看答案、“秒”知错、“秒”提升。考生在“真题”、“模拟练习”两个模块还可以选择练习模式和考试模式两种不同的方式考查自己对知识的理解。</p>
        <div>（4）12小时全程答疑服务</div>
        <p>为了方便考生备考，我团队提供了12小时全程答疑服务，该答疑服务可确保真人时时在线，你的问题不用担心，APP现有答案满足不了你的，可以选择人工答疑服务，考研学子的考研路之程保驾护航。</p>
        <div>（5）专业的网站运营</div>
        <p>拥有专业的网站运营技术团队，稳定的操作系统，独立操作后台，提供24小时全程技术服务。</p>
        <h5>2.极简化</h5>
        <p>金小央APP追求“极简化”设计理念。这里的“极简化”是指界面极简化，信息极简化。</p>
        <div>（1）界面极简化：</div>
        <p>四个模块“资讯”、“课程”、“题库”、“交流”四个功能界面清晰分布，每个模块提供的功能也用了极尽简化的设计，方便考生直接上手应用。</p>
        <div>（2）信息极简化：</div>
        <p>当学生用户确定了报考的目标院校、目标专业后，金小央APP将只提供与此相关的内容。这样的模式，有利于学生只关注与自身考试相关信息，避免冗杂信息影响复习效率。</p>
        <div>3.专业型</div>
        <p>该专业型的含义有二,
        第一个含义是指金小央APP在考生选定报考院校及报考专业后，所提供的所有信息均只有对应院校专业的信息，无其他冗杂信息。
        第二个含义是指金小央APP致力于为考生提供专业型的服务，在解决问题的效率上以及效果上都力求达到最优解。</p>
        <h3>三、主题颜色选择</h3>
        <div>我团队拟定金小央APP的主题颜色为橙色主要基于以下几个原因：</div>
        <p>1.从心理学来看，橙色是欢快活泼的光辉色彩，是暖色系中最温暖的色。它会给房间带来温暖的感觉，但这种温暖并不那么强烈，因为它是友好而亲切的。</p>
        <p>无论窗外春夏秋冬四季如何变换，在考研人心里似乎只有“冬”。我们要经历春天选学校、选专业的迷茫，夏天焦灼的复习，秋天报考以及对自己复习程度的不自信，冬天迎接考试的来临，此番历程每一步都甚是艰辛。所以，我们选择了颜色中最容易给予人温暖感的橙色，以期最大程度的降低考生的寒冷感。</p>
        <p>2.从谐音来看，“橙色”音意考研这事儿“成”了！</p>
        <p>3.排除其他几个常用颜色的主要原因是：</p>
        <p>红色虽热情、活泼、张扬，但它带给人的视觉冲击力太强，而且，生活中出门遇“红灯”也着实让人添堵，所以我们排除了红色。</p>
        <p>绿色清新、健康，但股民及沐浴在爱情中的小可人儿们可不喜欢这个颜色，排除。</p>
        <p>黄色灿烂、辉煌，有着太阳般的光辉，但考研这事儿不能黄，排除。</p>
        <p>蓝色理智、大方，是永恒的象征，但使用者多用官方机构，而且蓝色属于冷色系，它是最冷的色彩，排除。</p>
        <h3>四、logo设计理念</h3>
        <img style="width: 30%;" src="../../public/logo.png" alt="">
        <div>金小央APP的logo由一个非常圆润的“金”字加两个小船帆组成，其寓意如下：</div>
        <h5>1.“金”寓意有二。</h5>
        <p>第一个含义是“金榜题名”。金小央APP是一款主打考研服务的软件，我们这里的“金”就含有预祝各位考生金榜题名的含义。</p>
        <p>第二个含义是“金色前程”。寓意考生前程似锦，“金”字采用圆润设计预祝考生一路顺利！</p>
        <h5>2.下面的金黄色的“小船帆”寓意有五。</h5>
        <p>第一个含义预祝考生考研整个期间一帆风顺。</p>
        <p>第二个含义是取自《圣经》的诺亚方舟故事，诺亚制造了一个巨大的方舟拯救了人类，金小央APP虽然做不到“拯救”，但是希望能在考研路上给考生以帮助。</p>
        <p>第三个含义是让两个孤独备考的考生（小船）能够沟通、交流起来，备考路上虽然有物理上的间隔，但是在网络上能够实现及时沟通从而掌握最新信息。
第四个含义则是元宝。</p>
<p>扬起的风帆采用黄色，把两个孤独的小船压扁了看起来就跟一个元宝一样，预祝各位考生财源滚滚来。</p>
<p>第五个含义是领航，即金小央APP对考生初试、复试、实习就业（复试、实习就业正在部署中）等提供指导和领航的作用。</p>
    </div>
  </div>
</template>

<script>

export default {
}
</script>
<style lang="scss" scoped>
.home-container {
  width: 100%;
  display: flex;
  justify-content: center;
  .home {
    width: 1200px;
    // text-align: center;
    padding: 60px 0;
    h3 {
      font-size: 28px;
      font-weight: normal;
      padding: 10px 0;
    }
    h5 {
      font-size: 24px;
      font-weight: normal;
      padding: 10px 0;
    }
    p, div {
      font-size: 16px;
      line-height: 28px;
      // margin-top: 20px;
      // text-indent: 2em;
      padding: 5px 0;
      // padding: 0 60px;
    }
  }
}
</style>
